var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"ma-4"},[_c('v-toolbar',[_c('v-toolbar-title',[_vm._v("Timeclock Manager")]),_c('v-spacer'),(_vm.user.roles.includes('Technology Services'))?[_c('v-btn-toggle',{model:{value:(_vm.view),callback:function ($$v) {_vm.view=$$v},expression:"view"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"value":"table","icon":""}},on),[_c('v-icon',[_vm._v("fal fa-th-list")])],1)]}}],null,false,3818973627)},[_c('span',[_vm._v("List/Table View")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"value":"grid","icon":""}},on),[_c('v-icon',[_vm._v("fal fa-th")])],1)]}}],null,false,2206306578)},[_c('span',[_vm._v("Grid/Screenshot View")])])],1),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"to":"/scotswork/timeclock/usage","icon":""}},on),[_c('v-icon',[_vm._v("fal fa-calendar-alt")])],1)]}}],null,false,642330702)},[_c('span',[_vm._v("Timeclock Usage Details (work-in-progress)")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"to":"/scotswork/timeclock/log","icon":""}},on),[_c('v-icon',[_vm._v("fal fa-file-alt")])],1)]}}],null,false,3557126139)},[_c('span',[_vm._v("Timeclock Logs (work-in-progress)")])])]:_vm._e(),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"to":"/scotswork/timeclock/detail","exact":"","icon":""}},on),[_c('v-icon',[_vm._v("fal fa-eye")])],1)]}}])},[_c('span',[_vm._v("View Timeclock scan detail")])]),(_vm.user.roles.includes('Technology Services'))?_c('v-menu',{scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""}},on),[_c('v-icon',[_vm._v("fal fa-ellipsis-v")])],1)]}}],null,false,675259685)},[_c('v-list',[_c('v-list-item',{on:{"click":function($event){return _vm.sendCommand('reload')}}},[_c('v-list-item-title',[_vm._v("Reload Start URL")])],1),_c('v-list-item',{on:{"click":function($event){return _vm.sendCommand('screenshot')}}},[_c('v-list-item-title',[_vm._v("Get Screenshots")])],1)],1)],1):_vm._e()],2),(_vm.view === 'grid')?_c('v-card-text',[_c('v-row',_vm._l((_vm.timeclocks),function(ref){
var _id = ref._id;
var shortName = ref.shortName;
var screenshot = ref.screenshot;
return _c('v-col',{key:_id,attrs:{"cols":12,"sm":"6","md":"4","lg":"3","xl":"2"}},[_c('v-card',[_c('v-img',{attrs:{"src":screenshot}}),_c('v-toolbar',[_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.expandScreenshot(_id)}}},[_c('v-icon',[_vm._v("fal fa-expand-arrows")])],1),_c('v-spacer'),_c('span',[_vm._v(_vm._s(shortName))]),_c('v-spacer'),_c('v-menu',{scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""}},on),[_c('v-icon',[_vm._v("fal fa-ellipsis-v")])],1)]}}],null,true)},[_c('v-list',[_c('v-list-item',{on:{"click":function($event){return _vm.sendCommand('screenshot', _id)}}},[_c('v-list-item-title',[_vm._v("Refresh Screenshot")])],1),_c('v-list-item',{on:{"click":function($event){return _vm.sendCommand('reload', _id)}}},[_c('v-list-item-title',[_vm._v("Reload Page")])],1)],1)],1)],1)],1)],1)}),1)],1):_c('v-data-table',{attrs:{"items":_vm.timeclocks,"headers":_vm.headers,"items-per-page":_vm.timeclocks.length,"footer-props":{ 'items-per-page-options': [20, 30] }},on:{"dblclick:row":function (e, ref) {
var item = ref.item;
 _vm.loadDetail(item._id) }},scopedSlots:_vm._u([{key:"item.ip",fn:function(ref){
var item = ref.item;
return [_c('a',{attrs:{"href":'http://' + item.ip + ':2323',"target":"_blank"}},[_vm._v(_vm._s(item.ip))])]}},{key:"item.lastPing",fn:function(ref){
var item = ref.item;
return [(item.pingSeconds > 300)?_c('v-alert',{staticStyle:{"margin-bottom":"0"},attrs:{"type":"error","dense":""}},[_vm._v(_vm._s(item.pingTimeAgo))]):(item.pingSeconds > 120)?_c('v-alert',{staticStyle:{"margin-bottom":"0"},attrs:{"type":"warning","dense":""}},[_vm._v(_vm._s(_vm.stringFormatDateToday(item.lastPing)))]):(item.lastPing)?_c('v-alert',{staticStyle:{"margin-bottom":"0"},attrs:{"type":"success","dense":""}},[_vm._v(_vm._s(_vm.stringFormatDateToday(item.lastPing)))]):_c('v-alert',{staticStyle:{"margin-bottom":"0"},attrs:{"type":"error","dense":""}},[_vm._v("Configured; never pinged server")])]}},{key:"item.lastScan",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(_vm.stringFormatDateToday(item.lastScan)))]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"small":"","icon":""},on:{"click":function($event){return _vm.loadDetail(item._id)}}},on),[_c('v-icon',[_vm._v("fal fa-eye")])],1)]}}],null,true)},[_c('span',[_vm._v("View timeclock scan detail for "+_vm._s(item.location))])])]}}])}),_c('v-dialog',{attrs:{"fullscreen":""},model:{value:(_vm.fullscreenScreenshot),callback:function ($$v) {_vm.fullscreenScreenshot=$$v},expression:"fullscreenScreenshot"}},[(_vm.selectedId)?_c('v-card',[_c('v-toolbar',[_c('v-toolbar-title',[_vm._v(_vm._s(_vm.selectedTimeclock.shortName)+" Screenshot")]),_c('v-spacer'),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){_vm.fullscreenScreenshot = false}}},[_c('v-icon',[_vm._v("fal fa-times")])],1)],1),_c('v-img',{attrs:{"src":_vm.selectedTimeclock.screenshot}})],1):_vm._e()],1),_c('v-dialog',{attrs:{"fullscreen":""},model:{value:(_vm.commandResultDialog),callback:function ($$v) {_vm.commandResultDialog=$$v},expression:"commandResultDialog"}},[_c('v-card',[_c('v-toolbar',[_c('v-toolbar-title',[_vm._v("Command Results")]),_c('v-spacer'),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){_vm.commandResultDialog = false}}},[_c('v-icon',[_vm._v("fal fa-times")])],1)],1),(_vm.commandRunning)?_c('v-progress-linear',{model:{value:(_vm.commandProgress),callback:function ($$v) {_vm.commandProgress=$$v},expression:"commandProgress"}}):_vm._e(),(_vm.commandToExecute = 'screenshot')?_c('v-card-text',[_c('v-row',_vm._l((_vm.timeclocks),function(ref){
var ip = ref.ip;
var shortName = ref.shortName;
var screenshot = ref.screenshot;
return _c('v-col',{key:ip,attrs:{"cols":3}},[_c('v-card',[_c('v-img',{attrs:{"src":screenshot}}),_c('v-toolbar',[_c('v-spacer'),_c('span',[_vm._v(_vm._s(shortName))]),_c('v-spacer')],1)],1)],1)}),1)],1):_c('v-expansion-panels',_vm._l((_vm.timeclocks),function(ref){
var ip = ref.ip;
var shortName = ref.shortName;
var result = ref.result;
return _c('v-expansion-panel',{key:ip},[_c('v-expansion-panel-header',[_vm._v(_vm._s(shortName))]),_c('v-expansion-panel-content',[(_vm.commandToExecute = 'screenshot')?_c('v-img',{attrs:{"src":result}}):_c('div',{attrs:{"html":result}})],1)],1)}),1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
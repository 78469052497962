<template>
  <v-card class="ma-4">
    <v-toolbar>
      <v-toolbar-title>Timeclock Manager</v-toolbar-title>
      <v-spacer />
      <template v-if="user.roles.includes('Technology Services')">
        <v-btn-toggle v-model="view">
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-btn v-on="on" value='table' icon>
                <v-icon>fal fa-th-list</v-icon>
              </v-btn>
            </template>
            <span>List/Table View</span>
          </v-tooltip>
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-btn v-on="on" value='grid' icon>
                <v-icon>fal fa-th</v-icon>
              </v-btn>
            </template>
            <span>Grid/Screenshot View</span>
          </v-tooltip>
        </v-btn-toggle>
        <v-tooltip top>
          <template v-slot:activator="{ on }">
            <v-btn v-on="on" to="/scotswork/timeclock/usage" icon>
              <v-icon>fal fa-calendar-alt</v-icon>
            </v-btn>
          </template>
          <span>Timeclock Usage Details (work-in-progress)</span>
        </v-tooltip>
        <v-tooltip top>
          <template v-slot:activator="{ on }">
            <v-btn v-on="on" to="/scotswork/timeclock/log" icon>
              <v-icon>fal fa-file-alt</v-icon>
            </v-btn>
          </template>
          <span>Timeclock Logs (work-in-progress)</span>
        </v-tooltip>
      </template>
      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-btn v-on="on" to="/scotswork/timeclock/detail" exact icon>
            <v-icon>fal fa-eye</v-icon>
          </v-btn>
        </template>
        <span>View Timeclock scan detail</span>
      </v-tooltip>
      <v-menu v-if="user.roles.includes('Technology Services')">
        <template v-slot:activator="{ on }">
          <v-btn v-on="on" icon>
            <v-icon>fal fa-ellipsis-v</v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item @click="sendCommand('reload')">
            <v-list-item-title>Reload Start URL</v-list-item-title>
          </v-list-item>
          <v-list-item @click="sendCommand('screenshot')">
            <v-list-item-title>Get Screenshots</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-toolbar>
    <v-card-text v-if="view === 'grid'">
      <v-row>
        <v-col v-for="{ _id, shortName, screenshot } of timeclocks" :key="_id" :cols="12" sm="6" md="4" lg="3" xl="2">
          <v-card>
            <v-img :src="screenshot" />
            <v-toolbar>
              <v-btn icon @click="expandScreenshot(_id)">
                <v-icon>fal fa-expand-arrows</v-icon>
              </v-btn>
              <v-spacer />
              <span>{{ shortName }}</span>
              <v-spacer />
              <v-menu>
                <template v-slot:activator="{ on }">
                  <v-btn v-on="on" icon>
                    <v-icon>fal fa-ellipsis-v</v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item @click="sendCommand('screenshot', _id)">
                    <v-list-item-title>Refresh Screenshot</v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="sendCommand('reload', _id)">
                    <v-list-item-title>Reload Page</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </v-toolbar>
          </v-card>
        </v-col>
      </v-row>
    </v-card-text>
    <v-data-table v-else :items="timeclocks" :headers="headers" :items-per-page="timeclocks.length"  :footer-props="{ 'items-per-page-options': [20, 30] }" @dblclick:row="(e, { item }) => { loadDetail(item._id) }">
      <template v-slot:item.ip="{ item }">
        <a :href="'http://' + item.ip + ':2323'" target="_blank">{{ item.ip }}</a>
      </template>
      <template v-slot:item.lastPing="{ item }">
        <v-alert v-if="item.pingSeconds > 300" type="error" dense style="margin-bottom:0">{{ item.pingTimeAgo }}</v-alert>
        <v-alert v-else-if="item.pingSeconds > 120" type="warning" dense style="margin-bottom:0">{{ stringFormatDateToday(item.lastPing) }}</v-alert>
        <v-alert v-else-if="item.lastPing" type="success" dense style="margin-bottom:0">{{ stringFormatDateToday(item.lastPing) }}</v-alert>
        <v-alert v-else type="error" dense style="margin-bottom:0">Configured; never pinged server</v-alert>
      </template>
      <template v-slot:item.lastScan="{ item }">{{ stringFormatDateToday(item.lastScan) }}</template>
      <template v-slot:item.actions="{ item }">
        <v-tooltip top>
          <template v-slot:activator="{ on }">
            <v-btn v-on="on" small icon @click="loadDetail(item._id)">
              <v-icon>fal fa-eye</v-icon>
            </v-btn>
          </template>
          <span>View timeclock scan detail for {{ item.location }}</span>
        </v-tooltip>
      </template>
    </v-data-table>
    <v-dialog v-model="fullscreenScreenshot" fullscreen>
      <v-card v-if="selectedId">
        <v-toolbar>
          <v-toolbar-title>{{ selectedTimeclock.shortName }} Screenshot</v-toolbar-title>
          <v-spacer />
          <v-btn @click="fullscreenScreenshot = false" icon>
            <v-icon>fal fa-times</v-icon>
          </v-btn>
        </v-toolbar>
        <v-img :src="selectedTimeclock.screenshot" />
      </v-card>
    </v-dialog>
    <v-dialog v-model="commandResultDialog" fullscreen>
      <v-card>
        <v-toolbar>
          <v-toolbar-title>Command Results</v-toolbar-title>
          <v-spacer />
          <v-btn @click="commandResultDialog = false" icon>
            <v-icon>fal fa-times</v-icon>
          </v-btn>
        </v-toolbar>
        <v-progress-linear v-if="commandRunning" v-model="commandProgress" />
        <v-card-text v-if="commandToExecute = 'screenshot'">
          <v-row>
            <v-col v-for="{ ip, shortName, screenshot } of timeclocks" :key="ip" :cols="3">
              <v-card>
                <v-img :src="screenshot" />
                <v-toolbar>
                  <v-spacer />
                  <span>{{ shortName }}</span>
                  <v-spacer />
                </v-toolbar>
              </v-card>
            </v-col>
          </v-row>
        </v-card-text>
        <v-expansion-panels v-else>
          <v-expansion-panel v-for="{ ip, shortName, result } of timeclocks" :key="ip">
            <v-expansion-panel-header>{{ shortName }}</v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-img v-if="commandToExecute = 'screenshot'" :src="result" />
              <div v-else :html="result"></div>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import { ref, computed, onMounted, onBeforeUnmount, watch } from '@vue/composition-api'
import { stringFormatDate, stringFormatDateToday } from '../../../helpers/formatters'
import { getTimeDiff } from '../../../helpers/diffs'

export default {
  setup (props, { root }) {
    const user = computed(() => root.$store.state.user.spoof || root.$store.state.user)
    const view = ref('table')
    const timeclocks = ref([])
    const headers = computed(() => {
      const arr = [
        { text: 'Location', value: 'shortName' },
        { text: 'Last Ping', value: 'lastPing' },
        { text: 'Last Scan', value: 'lastScan' },
        { text: '', value: 'actions' }
      ]
      if (user.value.roles.includes('Technology Services')) {
        arr.splice(1, 0, { text: 'IP Address', value: 'ip' })
      }
      return arr
    })
    const filter = computed({
      get: () => root.$store.state['work-study'].timeclockFilter,
      set: (val) => {
        root.$store.commit('work-study/setTimeclockFilter', val)
        load()
      }
    })

    const service = root.$feathers.service('timeclock/location')

    async function load () {
      const query = { $limit: 50, $sort: { shortName: 1 } }
      const { data } = await service.find({ query })
      const ids = data.map(({ _id }) => _id)
      const { data: inData } = await root.$feathers.service('timeclock/punch').find({ query: { aggregate: [{ $match: { 'in.timeclock': { $in: ids } } }, { $group: { _id: '$in.timeclock', lastScan: { $max: '$in.date' } } }] } })
      const { data: outData } = await root.$feathers.service('timeclock/punch').find({ query: { aggregate: [{ $match: { 'out.timeclock': { $in: ids } } }, { $group: { _id: '$out.timeclock', lastScan: { $max: '$out.date' } } }] } })

      data.forEach((row) => {
        const inRec = inData.find(({ _id }) => _id === row._id)
        const outRec = outData.find(({ _id }) => _id === row._id)
        if (inRec && outRec) {
          row.lastScan = inRec.lastScan > outRec.lastScan ? inRec.lastScan : outRec.lastScan
        } else if (inRec) {
          row.lastScan = inRec.lastscan
        } else if (outRec) {
          row.lastScan = outRec.lastScan
        }
        const obj = updateTimeLabel(row)
        const index = timeclocks.value.findIndex(({ _id }) => _id === row._id)
        if (index >= 0) timeclocks.value.splice(index, 1, { ...timeclocks.value[index], obj })
        else timeclocks.value.push(obj)
      })
    }

    function updateTimeLabel (temp) {
      temp.pingSeconds = getTimeDiff(new Date(), temp.lastPing, true)
      const diff = getTimeDiff(new Date(), temp.lastPing).split(',')
      temp.pingTimeAgo = 'More than ' + diff[0] + (diff.length > 1 ? ' ago' : '')
      return temp
    }

    const interval = ref(null)
    onMounted(() => {
      load()
      interval.value = setInterval(load, 60000)
    })
    onBeforeUnmount(() => {
      clearInterval(interval.value)
    })

    const newTimeclockDialog = ref(false)
    const newTimeclockLocation = ref('')
    function saveNewTimeclock () {
      service.create({ location: newTimeclockLocation.value, locked: false }).then(() => {
        newTimeclockDialog.value = false
        load()
      })
    }

    function loadDetail (id) {
      root.$store.commit('work-study/setTimeclockFilter', { timeclocks: [id], student: '', dates: [] })
      root.$router.push('/scotswork/timeclock/detail')
    }

    const commandRunning = ref(false)
    const commandProgress = ref(0)
    const commandToExecute = ref('')
    const commandResultDialog = ref(false)

    async function sendCommand (command, timeclock) {
      commandToExecute.value = command
      commandRunning.value = true
      commandProgress.value = 0
      if (timeclock) {
        const res = await root.$feathers.service('timeclock/location').find({ query: { command, timeclock } })
        const index = timeclocks.value.findIndex(({ _id }) => _id === res._id)
        if (index >= 0) timeclocks.value.splice(index, 1, { ...timeclocks.value[index], ...res })
      } else {
        // Run for all timeclocks
        for (const { _id } of timeclocks.value) {
          root.$feathers.service('timeclock/location').find({ query: { command, timeclock: _id } }).then((res) => {
            const index = timeclocks.value.findIndex(({ _id }) => _id === res._id)
            if (index >= 0) timeclocks.value.splice(index, 1, { ...timeclocks.value[index], ...res })
            commandProgress.value += (100 / timeclocks.value.length)
          })
        }
      }
    }
    watch(view, () => {
      if (view.value === 'grid') {
        sendCommand('screenshot')
      }
    })

    const fullscreenScreenshot = ref(false)
    const selectedId = ref('')
    const selectedTimeclock = computed(() => timeclocks.value.find(({ _id }) => _id === selectedId.value))
    function expandScreenshot (_id) {
      selectedId.value = _id
      fullscreenScreenshot.value = true
    }

    return {
      user,
      view,
      timeclocks,
      headers,
      filter,
      newTimeclockDialog,
      newTimeclockLocation,
      saveNewTimeclock,
      loadDetail,
      commandRunning,
      commandProgress,
      commandToExecute,
      sendCommand,
      commandResultDialog,
      fullscreenScreenshot,
      selectedId,
      selectedTimeclock,
      expandScreenshot,
      stringFormatDate,
      stringFormatDateToday
    }
  }
}
</script>
